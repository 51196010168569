/**
 * Slanted bar parallax effect for subheaders/hero area
 */
.parallax-header {

    .parallax--front {
        position: absolute;
        top: auto;
        left: 3rem;
        bottom: 82px;
        z-index: 3;
        width: 8rem;
        height: 8rem;
        padding-right: 0;
        padding-bottom: 0;
        // border-radius: 50rem;
        // background-color: var(--dcs-primary);
        &:before {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            background-color: var(--dcs-secondary);
            transform: skewX(-15deg);
        }
    }
    .parallax--back {
        position: absolute;
        top: -2rem;
        right: 11rem;
        z-index: -3;
        width: 12rem;
        height: 12rem;
        // border-radius: 50rem;
        // background-color: var(--dcs-primary);
        &:before {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            background-color: var(--dcs-primary);
            transform: skewX(-15deg);
        }
    }
}


@media screen and (max-width: 1067px) {
    .parallax-header{
        .parallax--back{
            right: 7rem;
            top: 1rem;
            }
        .parallax--front{
            bottom:1rem;
        }
    }
}

@media screen and (max-width: 991px) {
    .parallax-header{
        .parallax--back{
            right: 20rem;
            top:-3rem
            }
        .parallax--front{
            bottom: 1rem;
            right: 36rem;
        }
    }
}

@media screen and (max-width: 791px) {
    .parallax-header{
        .parallax--back{
            right: 13rem;
            top: -1rem;
            }
    }
}

@media screen and (max-width: 615px) {
    .parallax-header{
        .parallax--back{
            right: 10rem;
            top: -1rem;
        }
    }
}

@media screen and (max-width: 400px) {
    .parallax-header{
        .parallax--back{
            right: 5rem;
            top: -1rem;
            width: 6rem;
            height: 6rem;
            }
        .parallax--front{
            right: 16rem;
            width: 4rem;
            height: 4rem;
            bottom: 49px;
        }
    }
}




/**
 *
 */
.faq{
    display: flex;
    flex-direction: column;

    dl {

        dt {
            @extend h5;
            position: relative;
            background-color: white;
            border-radius: 0.5rem;
            // min-height: 4rem;
            padding: 0.75rem 1rem;
            display: flex;
            align-items: flex-start;
            margin-bottom: 0;

            &:before {
                @extend h5;
                content: 'Q.';
                min-width: 2rem;
                // font-size: 2rem;
                font-weight: bold;
                margin-bottom: 0;
                // text-align: center;
                color: var(--dcs-primary);
            }
                    
            &:after{
                content: '';
                position: absolute;
                top:0;
                left:0;
                width: 4px;
                height: 100%;
                background-color: var(--dcs-primary);
                border-radius: 0.5rem 0 0 0
            }
        }

        dd {
            position: relative;
            background-color: rgba(var(--dcs-light-rgb), 1);
            border-radius: 0.5rem;
            // min-height: 4rem;
            padding: 0.75rem 1rem;
            display: flex;
            align-items: flex-start;
            margin-bottom: 0;

            &:before {
                @extend h5;
                content: 'A.';
                min-width: 2rem;
                // font-size: 2rem;
                font-weight: bold;
                margin-bottom: 0;
                // text-align: center;
                color: var(--dcs-gray);
            }
                    
            &:after{
                content: '';
                position: absolute;
                top:0;
                left:0;
                width: 4px;
                height: 100%;
                background-color: var(--dcs-gray);
                border-radius:  0 0 0 0.5rem;
            }
        }
    }
}
/**/