.rightdiv{
  background: linear-gradient(to bottom, transparent calc(100% - 1px), transparent calc(100% - 1px)) no-repeat, linear-gradient(to left, transparent calc(100% - 1px), var(--dcs-secondary) calc(100% - 1px)) no-repeat;
  background-position: center;
  background-size: calc(100% - 2rem) 100%, 100% calc(100% - 2rem);
  padding-left: 1rem;
  padding-right: 1rem;
}

.selfServiceButtons{
    background-color: inherit;
    float: left;
    cursor: pointer;
    transition: 0.3s;
    position: relative;
    a{
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        text-decoration: none;
    }
    &:hover{
        /* Change background color of buttons on hover */
        background-color: #ddd;
        border-radius: 1rem;
        a{
          color: black;
          transition: all .2s;
        }
      }
}
.buttonborder{
    background-color: inherit;
    float: left;
    cursor: pointer;
    padding: 14px 16px;
    transition: 0.3s;
    height: 100%;
    padding: 10px 20px;
    width: 100%;
    position: relative;
    background: linear-gradient(to bottom, transparent calc(100% - 1px), var(--dcs-secondary) calc(100% - 1px)) no-repeat, linear-gradient(to left, transparent calc(100% - 1px), var(--dcs-secondary) calc(100% - 1px)) no-repeat;
    background-position: center;
    background-size: calc(100% - 2rem) 100%, 100% calc(100% - 2rem);
}

.buttonborderleft{
    background-color: inherit;
    float: left;
    cursor: pointer;
    padding: 14px 16px;
    transition: 0.3s;
    height: 100%;
    padding: 10px 20px;
    width: 100%;
    position: relative;
    background: linear-gradient(to bottom, transparent calc(100% - 1px), var(--dcs-secondary) calc(100% - 1px)) no-repeat;
    background-position: center;
    background-size: calc(100% - 2rem) 100%, 100% calc(100% - 2rem);
  }

  .buttonborderleftbottom{
    background-color: inherit;
    float: left;
    cursor: pointer;
    padding: 14px 16px;
    transition: 0.3s;
    height: 100%;
    padding: 10px 20px;
    width: 100%;
    position: relative;
    background-position: center;
    border-radius: 1rem;
  }

  .buttonborderbottom, .buttonborderrightbottom{
    background-color: inherit;
    float: left;
    cursor: pointer;
    padding: 14px 16px;
    transition: 0.3s;
    height: 100%;
    padding: 10px 20px;
    width: 100%;
    position: relative;
    background: linear-gradient(to bottom, transparent calc(100% - 1px), transparent calc(100% - 1px)) no-repeat, linear-gradient(to left, transparent calc(100% - 1px), var(--dcs-secondary) calc(100% - 1px)) no-repeat;
    background-position: center;
    background-size: calc(100% - 2rem) 100%, 100% calc(100% - 2rem);
  }

  @media(max-width: 767px){
    .mapdiv{
      width: 100%;
    }
    
    .buttonborder, .buttonborderleft,.buttonborderbottom, .buttonborderleftbottom{
      background-color: inherit;
      float: left;
      cursor: pointer;
      padding: 14px 16px;
      transition: 0.3s;
      height: 100%;
      width: 100%;
      position: relative;
      background: linear-gradient(to bottom, transparent calc(100% - 1px), var(--dcs-secondary) calc(100% - 1px)) no-repeat;
      background-position: center;
      background-size: calc(100% - 2rem) 100%, 100% calc(100% - 2rem);
    }
    .buttonborderrightbottom{
      background-color: inherit;
      float: left;
      cursor: pointer;
      transition: 0.3s;
      height: 100%;
      padding: 10px 20px;
      width: 100%;
      position: relative;
      background-position: center;
      background: none;
      background-size: calc(100% - 2rem) 100%, 100% calc(100% - 2rem);
    }
    .rightdiv{
      background: none;
      padding-left: 0;
    }
    .contacts{
      svg{
        width: 1rem;
        height: 1rem;
      }
    }
  }