/**
 * custom range slider
 */
:root {
    --dcs-range-value-bg: var(--dcs-light);
    --dcs-range-thumb-color: var(--dcs-primary);
    --dcs-range-track-color: rgba(0, 20, 137, .5);
}
.range-slider {
    /* margin: 0 0 60px 0%; */
    margin-left: 0%;
    font-size: 1rem;
    // width: 100%;
}
    
.range-slider-range {
    width: calc(100% - (120px));
    height: 0.5em;
    border-radius: 1em;
    background: var(--dcs-range-track-color);
    outline: none;
    padding: 0;
    margin: 0;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
}
.range-slider-range::-webkit-slider-thumb {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    width: 1.1em;
    height: 1.1em;
    border-radius: 50%;
    background: var(--dcs-range-thumb-color);
    cursor: pointer;
    -webkit-transition: background .15s ease-in-out;
            transition: background .15s ease-in-out;
    // margin-top: -0.5em;
}
.range-slider-range::-webkit-slider-thumb:hover {
    background: var(--dcs-range-thumb-color);
}
.range-slider-range:active::-webkit-slider-thumb {
    // background: var(--dcs-range-thumb-color);
    box-shadow: 0 0 0 0.25rem rgba(0, 20, 137, 0.25);
}
.range-slider-range::-moz-range-thumb {
    width: 1.1em;
    height: 1.1em;
    border: 0;
    border-radius: 50%;
    background: var(--dcs-range-thumb-color);
    cursor: pointer;
    -moz-transition: background .15s ease-in-out;
         transition: background .15s ease-in-out;
}
.range-slider-range::-moz-range-thumb:hover {
    background: var(--dcs-range-thumb-color);
}
.range-slider-range:active::-moz-range-thumb {
    background: var(--dcs-range-thumb-color);
}
.range-slider-range:focus::-webkit-slider-thumb {
    // box-shadow: 0 0 0 3px #fff, 0 0 0 6px var(--dcs-range-thumb-color);
    box-shadow: 0 0 0 0.3rem rgba(0, 20, 137, 0.25)
}
    
.range-slider-value {
    display: inline-block;
    position: relative;
    /* width: 60px; */
    width: 100px;
    /* color: #fff; */
    line-height: 1.1em;
    text-align: center;
    border-radius: 3px;
    /* background: #2c3e50; */
    background: var(--dcs-range-value-bg);
    padding: 5px 10px;
    margin-left: 8px;
}
.range-slider-value:after {
    position: absolute;
    top: 8px;
    left: -7px;
    width: 0;
    height: 0;
    /* border-top: 7px solid transparent;
    border-right: 7px solid #2c3e50;
    border-bottom: 7px solid transparent; */
    border-top: 7px solid transparent;
    border-right: 7px solid var(--dcs-range-value-bg);
    border-bottom: 7px solid transparent;
    content: '';
}
    
::-moz-range-track {
    background: var(--dcs-range-value-bg);
    border: 0;
}
    