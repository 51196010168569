.graphimg{
    width: 925px;
}

.hfcheader{
    .headerimg{
        img{
            filter: brightness(1.1);
        }
    }
}

#cardstnc{
    li{
        padding: 0.5rem 0 0.5rem;
    }
}



.tablegraphdiv{
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    padding: 1rem 0 2rem;
    .tablediv{
        width: fit-content;
        height: fit-content;
        padding-bottom: 1rem;
        .spendingtiertable{
            border-radius: 1rem;
            overflow: hidden;
            border-style: solid;
            th, td, tr{
                padding: 0.5rem 3rem 0.5rem;
            }
            @media(max-width: 437px) {
                th, td, tr{
                    padding: 0.5rem 1rem 0.5rem;
                }
            }
            td{
                border: 1px solid;
                border-color: var(--dcs-gray-200);
            }
        }
    }
}
.examples{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    .example1, .example2, .example3, .example4{
        padding: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        h5{
            padding: 0 0 2rem;
        }
    }
}

@media screen and (max-width: 556px){
    .graphimg{
        width: 100%;
    }
}