
/**
 * custom position utilities
 */
// sizing
.w-lg-50 {
    width: 100% !important;
}
.top-25 {
    top: 25% !important;
}
@media (min-width: 992px) {

    // positioning
    .translate-lg-middle {
        -webkit-transform: translate(-50%, -50%) !important;
        transform: translate(-50%, -50%) !important;
    }

    .start-lg-50 {
        left: 50% !important;
    }
    .start-lg-100 {
        left: 100% !important;
    }

    .top-lg-25 {
        top: 25% !important;
    }
    .top-lg-50 {
        top: 50% !important;
    }
    .top-lg-100 {
        top: 100% !important;
    }

    // sizing
    .w-lg-50 {
        width: 50% !important;
    }
    
    .min-lg-vh-50 {
        min-height: 50vh !important;
    }
    .min-lg-vh-100 {
        min-height: 100vh !important;
    }

    .w-50 {
        width: 50% !important;
    }
}







/**
 * vertical divider with OR
 */
 .vertical-divider {
    // position: absolute;
    display: table;
    text-align: center;
    
    height: 100%; 
    width: 100%;

    .center-element {
        position: relative;
        display: table-cell;
        vertical-align: middle;

        &:before, 
        &:after {
            position: absolute;
            content: "";
            width: 1px;
            left: 50%;
            border-left: 1px solid rgba(0,0,0,.125);
        }

        &:before {
            bottom: 50%;
            top: 0;
            margin-bottom: 20px;
        }
        &:after {
            top: 50%;
            bottom: 0;
            margin-top: 20px;
        }
    }

    @media (max-width: 992px) {
        position: relative;
        
        .center-element {

            &:before, 
            &:after {
                width: auto;
                height: 1px;
                left: auto;
                top: 50%;
                border-left: 0;
                border-top: 1px solid rgba(0,0,0,.125);
            }
            
            &:before {
                right: 50%;
                left: 0;
                margin-right: 20px;
                margin-top: 0;
            }
            &:after {
                left: 50%;
                right: 0;
                margin-left: 20px;
                margin-top: 0;
            }
        }
    }
}
