
$enable-negative-margins: true;

// layout
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    // xxl: 1400px
    xxl: 1500px
);
$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1320px
    // xxl: 1420px
);

.position-lg-absolute {
    @media screen and (min-width: 992px) {
        position: absolute;
    }
}

:target {
    scroll-margin-top: 82px;
    // box-shadow: 0 0 0 0.25rem rgb(0 20 137 / 25%);
}