
/* lato-300 - latin */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-display: swap;
    font-weight: 300;
    src: local(''),
        url('/dcscc/fonts/lato-v22-latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('/dcscc/fonts/lato-v22-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* lato-regular - latin */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src: local(''),
        url('/dcscc/fonts/lato-v22-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('/dcscc/fonts/lato-v22-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* lato-700 - latin */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-display: swap;
    font-weight: 700;
    src: local(''),
        url('/dcscc/fonts/lato-v22-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('/dcscc/fonts/lato-v22-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* lato-900 - latin */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-display: swap;
    font-weight: 900;
    src: local(''),
        url('/dcscc/fonts/lato-v22-latin-900.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('/dcscc/fonts/lato-v22-latin-900.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
    font-family: 'Gotham';
    font-style: normal;
    font-display: swap;
    font-weight: 700;
    src: local(''),
        url('/dcscc/fonts/Gotham-Bold.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('/dcscc/fonts/Gotham-Bold_21010.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
    font-family: 'Gotham';
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src: local(''),
        url('/dcscc/fonts/Gotham-Book.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('/dcscc/fonts/gotham-book.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}