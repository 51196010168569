// .footer-nav{
//     display: flex;
//     flex-direction: column;
// }

// .main-footer-nav{
//     width: 80%;
//     display: flex;
//     justify-content: space-between;
//     align-items: flex-start;
//     flex-direction: row;
// }

// .footer-links{
//     width: 100%;
//     .row{
//         .nav{
//             margin-top: 0;
//         }
//     }
// }

.bg-primary-footer{
//     // background: -webkit-linear-gradient(top, rgb(0, 106, 165), #041e42de);
//     background-image: url('/dcscc/images/footer/blueblackimg.jpeg');
//     box-shadow: inset 0 0 0 1000px rgba(15, 25, 78, 0.463);
//     background-attachment: fixed;
//     background-repeat: no-repeat;
//     background-size: cover;
    background-color: $primary;
}

// .main-footer{
//     display: flex;
//     flex-direction: row;
//     flex-wrap: wrap;
//     align-content: center;
//     align-items: center;
//     justify-content: space-between;
//     a img{
//         transition: all 0.15s ease-in-out;
//         &:hover{
//             filter: brightness(0.85);
//         }    
//     }
// }

.subfooter{
    .bi-facebook{
        transition: all 0.15s ease-in-out;
        &:hover {
            filter: brightness(0.85);
        }
    }
}

.subfooter{
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;

}

.footer-nav-links{
    // padding: 0.5rem 1rem;
    text-decoration: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
    &:hover{
        color: var(--dcs-gray-400);
    }
}

// .nav-item{
//     padding: 1rem 0rem;
// }

@media screen and (max-width: 557px) {
    .main-footer{
        display: flex;
        flex-direction: column-reverse;
        flex-wrap: wrap;
        align-content: center;
        align-items: center;
        justify-content: center;
    }
    .subfooter{
        display: flex;
        flex-direction: column-reverse;
        flex-wrap: wrap;
        align-content: center;
        align-items: center;
        justify-content: center;
    }

    .footer-nav-links{
        display: inline-block;
    }
    // .bg-primary-footer{
    //     background-image: url('/dcscc/images/footer/blueblackimg-mobile.png');
    //     box-shadow: inset 0 0 0 1000px rgba(15, 25, 78, 0.463);
    //     background-attachment: local;
    //     background-position:center;
    //     background-repeat: no-repeat;
    //     background-size: cover;
    // }
    
}

@media screen and (min-width: 557px) and (max-width: 956px) {
    .main-footer{
        display: flex;
        flex-direction: column-reverse;
        flex-wrap: wrap;
        align-content: center;
        align-items: center;
        justify-content: center;
    }
    .subfooter{
        display: flex;
        flex-direction: column-reverse;
        flex-wrap: wrap;
        align-content: center;
        align-items: center;
        justify-content: center;
    }

    .footer-nav-links{
        display: inline-block;
    }

    // .bg-primary-footer{
    //     box-shadow: inset 0 0 0 1000px rgba(15, 25, 78, 0.463);
    //     background-attachment: fixed;
    //     background-position: center;
    //     background-repeat: no-repeat;
    //     background-size: cover;
    
    // }
}

// back to top fab
@import "totop";