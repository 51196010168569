.headerimg{
    display: flex;
    justify-content: center;
    img{
        width: 60%;
        padding: 1rem 0;
    }
}
.header{
    .circle{
        position: absolute;
        top: auto;
        right: 30rem;
        bottom: 82px;
        z-index: 3;
        width: 8rem;
        height: 8rem;
        padding-right: 0;
        padding-bottom: 0;
        border-radius: 50rem;
        background-color: var(--dcs-primary);
    }
    .backcircle{
        position: absolute;
        top: -2rem;
        right: 11rem;
        z-index: -3;
        width: 12rem;
        height: 12rem;
        border-radius: 50rem;
        background-color: var(--dcs-primary);
    }
}

.tablediv{
    width: 100%;
    height: 100%;
    padding-top: 1rem;
    padding-bottom: 3rem;
    overflow-x: auto;
    .table{
        margin: 0 auto;
        border-radius: 1rem;
        overflow: hidden;
        border-style: solid;
        th, td, tr{
            padding: 1rem;
        }
        td{
            border: 1px solid;
            border-color: var(--dcs-gray-200);
            #tablinks\ active {
                background-color: transparent;
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 0;
                border-top-right-radius: 0;
                border-top-left-radius: 0;
            }
        }
    }
    .theadpri{
        background-color: var(--dcs-primary);
        th{
            color: white
        }
    }
    tbody{
        background-color: white;
    }
}

.alink{
    text-decoration:none ;
    transition: all .2s;
}
.alink:hover{
    text-decoration: underline;
    color: black;
}

.paynowimg, .axsimg{
    display: flex;
    justify-content: center;
    img{
        width: 40%;
        padding: 1rem 0;
    }
}

.paymentchanneltabs{
    padding-top: 1rem;
    border-bottom: 2px solid var(--dcs-secondary);
    display: flex;
    cursor: pointer;
    align-items: baseline;
}

.bankgrid{
    text-align: center;
    .item{
        width: 30%;
    }
    a, a:hover{
        text-decoration: none;
    }
    a:hover{
        opacity: 80%;
    }
}
#tablinks\ active{
    color: white;
    .minus{
        display: inline-block;
    }
    .plus{
        display: none;
    }
}

.minus{
    display: none;
}
.minus, .plus{
    padding-right: 11px;
}

.dbsposb{
    display: flex;
    flex-direction: column;
    align-items: center;
    img{
        width: 30%;
    }
}
/** /
.dropdownlink{
    width: fit-content;
    .chevbottom{
        display: block;
    }
    .chevright{
        display: none;
    }
}
.dropdownlink.collapsed {
    .chevbottom{
        display: none;
    }
    .chevright{
        display: block;
    }
}
/**/
// #giro-bank{
//     padding: 0;
// }


@media screen and (max-width: 1067px) {
    .header{
        .backcircle{
            right: 7rem;
            top: 1rem;
            }
        .circle{
            bottom:1rem;
        }
    }
}

@media screen and (max-width: 991px) {
    .header{
        .backcircle{
            right: 20rem;
            top:-3rem
            }
        .circle{
            bottom: 1rem;
            right: 36rem;
        }
    }
}

@media screen and (max-width: 791px) {
    .header{
        .backcircle{
            right: 13rem;
            top: -1rem;
            }
    }
}

@media screen and (max-width: 615px) {
    .header{
        .backcircle{
            right: 10rem;
            top: -1rem;
        }
    }
}

@media screen and (max-width: 400px) {
    .header{
        .backcircle{
            right: 5rem;
            top: -1rem;
            width: 6rem;
            height: 6rem;
            }
        .circle{
            right: 16rem;
            width: 4rem;
            height: 4rem;
            bottom: 49px;
        }
    }
}