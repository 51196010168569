.parallax-img{
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 300px;
    opacity: 80%;
    display: flex !important;
    align-items: center;
    filter: brightness(0.6);
}

.caption{
    position: absolute;
    z-index: 1;
    width: 100%;
    text-align: center;
    color: white;
    height: 100%;
    top: 40%;
}

.noParallaxSATSdiv{
    display: none;
}

.satslogo {
    width: 91px;
}
.loungesdiv{
    justify-content: space-between;
}

@media(max-width: 767px){
    .noParallaxSATSdiv{
        display: block;
    }
    .SATSdiv{
        display: none;
    }
    .loungesdiv{
        justify-content: center;
    }
}