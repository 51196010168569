.readycashbtns{
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
}

.readycashbanner{
    display: flex;
    justify-content: center;
    img{
        width: 50%;
    }
}
@media(max-width: 820px){
    .readycashbanner{
        display: none;
    }
}
