
$font-family-sans-serif: "Lato", "Segoe UI", "Roboto", "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$headings-font-family: "Gotham", "Segoe UI", "Roboto", "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$headings-font-weight:        600;

$lead-font-weight: lighter;



// ordered list decimal
ol[type="1"] {
    list-style-type: none;
    counter-reset: item;
    margin: 0;
    padding: 0;
    
    & > li {
        display: table;
        counter-increment: item;
        margin-bottom: 0.6em;
    }
    & > li:before {
        content: counters(item, ".") ". ";
        display: table-cell;
        padding-right: 0.6em;
    }
    & > li li {
        margin: 0;
    }
    //    & > li li:before {
    //      content: counters(item, ".") ". ";
    //    }
}