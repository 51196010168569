/**
 * features custom styles
 */

.features{
    pointer-events: none;
    transition: background-color 200ms ease-out;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
    padding-top: 1rem;
    display: flex;
    flex-wrap: nowrap;

    &:hover {
        background-color: rgba(var(--dcs-light-rgb), 1);
        // .feature-detail{
        //     a{
        //         color: black;
        //         text-decoration: underline;
        //         text-underline-position: under;
        //     }
        // }
    }

    .feature-detail{
        height: 100%;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: flex-start;
        position: relative;

        a{
            padding-bottom: 1rem;
            text-decoration: none;
        }
    }

    .stretched-link {
        // for linked cards to show pointer cursor, 
        // and normal arrow cursor on non-linked cards
        pointer-events: auto;   
    }
    
}

@media screen and (max-width: 694px){
    .viewall{
        font-size: 75%;
        padding-left: 1rem;
    }
}

.icon{
    width: 3rem;
    position: relative;
    flex: none;
}
i{
    float: left;
}

.imgcircle1{
    border-radius: 50%;
    position: absolute;
    top: 15rem;
    right: 6rem;
    width: 12rem;
    z-index: -3;
    opacity: 90%;
}

.imgcircle2{
    border-radius: 50%;
    position: absolute;
    top: 24rem;
    right: 20rem;
    width: 10rem;
    z-index: -3;
    opacity: 90%;
}

@media screen and (max-width: 1286px){
    .imgcircle1{
        right: 3rem;
        width: 10rem;
    }
    .imgcircle2{
        right: 15rem;
        width: 9rem;
    }
}

@media screen and (max-width: 1200px){
    .imgcircle1{
        right: 3rem;
        width: 9rem;
    }
    .imgcircle2{
        right: 13rem;
        width: 8rem;
    }
}
@media screen and (max-width: 992px){
    .imgcircle1, .imgcircle2{
        display: none;
    }
}