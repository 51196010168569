.merchantimg{
    max-width: 80%;
}


.circle{
    position: absolute;
    top: auto;
    right: auto;
    bottom: 82px;
    z-index: 3;
    width: 8rem;
    height: 8rem;
    padding-right: 0;
    padding-bottom: 0;
    border-radius: 50rem;
    background-color: var(--dcs-primary);
  }

.backcircle{
    position: absolute;
    top: 2rem;
    right: 11rem;
    z-index: -3;
    width: 8rem;
    height: 8rem;
    border-radius: 50rem;
    background-color: var(--dcs-primary);
}

@media screen and (max-width: 1067px) {
    .backcircle{
        right: 7rem;
        top: 1rem;
        }
    .circle{
        bottom:1rem;
    }
}

@media screen and (max-width: 991px) {
    .backcircle{
        right: 20rem;
        }
    .circle{
        bottom: 1rem;
        width: 5rem;
        height: 5rem;
    }
}

@media screen and (max-width: 791px) {
    .backcircle{
        right: 13rem;
        top: -1rem;
        }
}

@media screen and (max-width: 615px) {
    .backcircle{
        right: 10rem;
        top: -1rem;
        }
}

@media screen and (max-width: 390px) {
    .backcircle{
        right: 5rem;
        top: -1rem;
        width: 6rem;
        height: 6rem;
        }
    .circle{
        width: 6rem;
        height: 6rem;
        bottom: 49px;
    }
}