#fab--toTop {
  visibility: hidden;
  position: fixed;
  bottom: 2rem;
  right: 1rem;
  z-index: 99;
  font-size: 18px;
  border: none;
  outline: none;
  background-color: rgba(85, 85, 85, 0.351);
  cursor: pointer;
  padding: 8px;
  border-radius: 100px;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  
  svg{
      fill: white;
  }
  
  &:hover {
    background-color: rgba(45, 45, 45, 0.4);
    svg{
      fill: white;
    }
  }
}