
/**
 * Cards custom styles
 */
.card {
    // pointer-events: none;
    transition: background-color 200ms ease-out;
    /** /
    a{
        text-decoration: none;
    }

    
    &:hover {
        background-color: white;

        .card-title {

            a {
                color: var(--dcs-dark);
                text-decoration: underline;
            }
        }
    }
    /**/

    .stretched-link {
        // for linked cards to show pointer cursor, 
        // and normal arrow cursor on non-linked cards
        pointer-events: auto;   
    }

    #stretched-link {
        // for linked cards to show pointer cursor, 
        // and normal arrow cursor on non-linked cards
        pointer-events: auto;
    }


    .overlay-img::after{
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: inline-block;
        background: rgba(0, 0, 0, 0.35);
        backdrop-filter: blur(0px);
        transition: background 0.2s ease-in, backdrop-filter 0.2s ease-in;  
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
        border-bottom-right-radius: 1rem;
        border-bottom-left-radius: 1rem;
    }

    .card-img-overlay{
        color: white;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: center;
        .card-title{
            a {
                color: var(--dcs-light);
                text-decoration: none;
            }
        }
    }
    
    
// club offers homepage
&.offer {

    .card-img-overlay {
        transition: opacity 200ms ease-out;
        opacity: 1;
        background-color: rgba(0, 0, 0, 0.75);
        backdrop-filter: blur(1.5px);
    }

    &:hover {
        .card-img-overlay {
            opacity: 1;
        }
    }
    
    @media not all and (hover: none) {

        .card-img-overlay {
            opacity: 0;
        }
    
    }
}

// club promos homepage
    &.promo{
        
        .card-title__wrapper{
            transition: 0.15s all ease;
            padding-bottom: 0rem;
            padding-left: 0rem;
        }

        &:hover{
        
            .card-title__wrapper{
                padding-bottom: 1rem;
                padding-left: 1rem;
            }

            .overlay-img {
                
                &:after{
                    background-color: rgba(0, 0, 0, 0.75);
                    backdrop-filter: blur(1.5px);
                }
            }
        }

        // .card-meta{
        //     small{
        //         font-size: 1rem;
        //     }
        // }

        // @media(max-width: 437px){
        //     .card-title {
        //         font-size: 0.95em;
        //     }
        //     .card-text{
        //         font-size: 80%;
        //     }
        //     &:hover{
        //         .card-title__wrapper{
        //             padding-bottom: 0.22rem;
        //             padding-left: 0.22rem;
        //         }
        //     }
        // }
    }
}

.merchantCard{
    .card-title{
        font-weight: 500;
    }
}

.tabcard{
    .card-body{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .card-title{
        padding: 10px 0 10px;
    }
}
@media(max-width: 419px){
    .merchantCard{
        .card-title{
            font-size: 100%;
        }
    }
}
