$card-spacer-y:                     1rem;
$card-spacer-x:                     1rem;
$card-title-spacer-y:               0.5rem;
// $card-border-width:                 $border-width;
// $card-border-color:                 rgba($black, .125);
$card-border-color:                 var(--dcs-gray);
$card-border-radius:                1rem;
// $card-box-shadow:                   null;
// $card-inner-border-radius:          subtract($card-border-radius, $card-border-width);
// $card-cap-padding-y:                $card-spacer-y * .5;
// $card-cap-padding-x:                $card-spacer-x;
// $card-cap-bg:                       transparent;
// $card-cap-color:                    null;
// $card-height:                       null;
// $card-color:                        null;
// $card-bg:                           transparent;
// $card-img-overlay-padding:          $spacer;
// $card-group-margin:                 $grid-gutter-width * .5;

.card-footer {
    .accordion-item:last-of-type {
        border-bottom-right-radius: 1rem;
        border-bottom-left-radius: 1rem;
    }
    .accordion-item:last-of-type .accordion-button.collapsed {
        border-bottom-right-radius: calc(1rem - 1px);
        border-bottom-left-radius: calc(1rem - 1px);
    }
}