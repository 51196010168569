.anslink {
    word-break: break-all;
}


.benefits, .benefitsbottom{
    border-radius: 1rem;
    padding: 1rem;
    a{
        text-decoration: none;
        transition: all .2s;
    }
    &:hover{
        background-color: white;
        a{
            color: black;
            text-decoration: underline;
        }
    }
}

.benefits{
    background: linear-gradient(to bottom, transparent calc(100% - 1px), var(--dcs-secondary) calc(100% - 1px)) no-repeat;
    background-position: center;
    background-size: calc(100% - 2rem) 100%, 100% calc(100% - 2rem);
}

.benefitnoalink{
    border-radius: 1rem;
    padding: 1rem;
    background: linear-gradient(to bottom, transparent calc(100% - 1px), var(--dcs-secondary) calc(100% - 1px)) no-repeat;
    background-position: center;
    background-size: calc(100% - 2rem) 100%, 100% calc(100% - 2rem);
}