/* Style the tab */
.tab {
    overflow: hidden;
    background-color: var(--bg-light);
    display: flex;
    flex-direction: row;
    justify-content: center;

    .tablinks{
      /* Style the buttons that are used to open the tab content */
      background-color: inherit;
      float: left;
      cursor: pointer;
      transition: 0.3s;
      min-height: 100px;
      position: relative;
      
      .tabborder{
        background-color: inherit;
        float: left;
        cursor: pointer;
        padding: 14px 16px;
        transition: 0.3s;
        height: 100%;
        padding: 10px 20px;
        width: 100%;
        position: relative;
        background: linear-gradient(to bottom, transparent calc(100% - 1px), var(--dcs-secondary) calc(100% - 1px)) no-repeat, linear-gradient(to left, transparent calc(100% - 1px), var(--dcs-secondary) calc(100% - 1px)) no-repeat;
        background-position: center;
        background-size: calc(100% - 2rem) 100%, 100% calc(100% - 2rem);
      }

      .tabborderbottom, .tabbordermostbottom{
        background-color: inherit;
        float: left;
        cursor: pointer;
        padding: 14px 16px;
        transition: 0.3s;
        height: 100%;
        padding: 10px 20px;
        width: 100%;
        position: relative;
        background: linear-gradient(to bottom, transparent calc(100% - 1px), transparent calc(100% - 1px)) no-repeat, linear-gradient(to left, transparent calc(100% - 1px), var(--dcs-secondary) calc(100% - 1px)) no-repeat;
        background-position: center;
        background-size: calc(100% - 2rem) 100%, 100% calc(100% - 2rem);
      }

      @media(max-width: 767px){
        .tabborder, .tabborderleft, .tabborderbottom, .tabborderbottomleft{
          background-color: inherit;
          float: left;
          cursor: pointer;
          padding: 14px 16px;
          transition: 0.3s;
          height: 100%;
          padding: 10px 20px;
          width: 100%;
          position: relative;
          background: linear-gradient(to bottom, transparent calc(100% - 1px), var(--dcs-secondary) calc(100% - 1px)) no-repeat;
          background-position: center;
          background-size: calc(100% - 2rem) 100%, 100% calc(100% - 2rem);
        }
        .tabbordermostbottom{
          background-color: inherit;
          float: left;
          cursor: pointer;
          padding: 14px 16px;
          transition: 0.3s;
          height: 100%;
          padding: 10px 20px;
          width: 100%;
          position: relative;
          background-position: center;
          background: none;
          background-size: calc(100% - 2rem) 100%, 100% calc(100% - 2rem);
        }
      }

      .tabborderleft{
        background-color: inherit;
        float: left;
        cursor: pointer;
        padding: 14px 16px;
        transition: 0.3s;
        height: 100%;
        padding: 10px 20px;
        width: 100%;
        position: relative;
        background: linear-gradient(to bottom, transparent calc(100% - 1px), var(--dcs-secondary) calc(100% - 1px)) no-repeat;
        background-position: center;
        background-size: calc(100% - 2rem) 100%, 100% calc(100% - 2rem);
      }

      .tabborderbottomleft{
        background-color: inherit;
        float: left;
        cursor: pointer;
        padding: 14px 16px;
        transition: 0.3s;
        height: 100%;
        padding: 10px 20px;
        width: 100%;
        position: relative;
        background-position: center;
        background-size: calc(100% - 2rem) 100%, 100% calc(100% - 2rem);
      }

      &:hover{
        /* Change background color of buttons on hover */
        background-color: #ddd;
        border-bottom-right-radius: 1rem;
        border-bottom-left-radius: 1rem;
        border-top-right-radius: 1rem;
        border-top-left-radius: 1rem;
      }

      .card-body{
        i{
          color: var(--dcs-primary);
        }
      }
    }
  }
  /* Style the tab content */
  .tabcontent {
    display: none;
    padding: 2rem 1rem;
    border-top: none;
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
    width: 100%;
    h3{
      padding-top: 1.5rem;
    }
  }
// to style the tab cards when active
  #tablinks\ active{
    background-color:var(--dcs-primary);
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
    .card-body{
      color: var(--dcs-light);
      i{
        color: var(--dcs-light);
      }
    }

    //to remove the border when its active
    .tabborder, .tabborderbottomleft, .tabborderleft, .tabbordermostbottom, .tabborderbottomleft, .tabborderbottom{
      background: transparent;
    }
  }