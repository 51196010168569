

[data-object-fit='cover'] {
    object-fit: cover;
}

[data-object-fit='contain'] {
    object-fit: contain;
}
/* .card-img-overlay.card-img-overlay {
    display: flex;
    flex-direction: column;
    justify-content: center;
} */

/* list */
ul.check li {
    list-style-image: url(/components/open-iconic/svg/check.svg);
}
ul.x li {
    list-style-image: url(/components/open-iconic/svg/x.svg);
}



/**
 * application process
 */

.numbered-circle {
    width: 60px;
    line-height: 60px;
    border-radius: 50%;
    text-align: center;
    font-size: 2rem;
    border: 2px solid currentColor;
    margin: 0 auto 1rem;
}
@media screen and (min-width: 768px){
    .numbered-circle {
        width: 100px;
        line-height: 100px;
    }
}
@media screen and (min-width: 992px){
    .numbered-circle {
        width: 120px;
        line-height: 120px;
    }
}


/* nice bootstrap ready facebook share button http://justincron.com */
.btn-facebook {
	color: #fff;
	background-color: #4C67A1;
}
.btn-facebook:hover {
	color: #fff;
	background-color: #405D9B;
}
.btn-facebook:focus {
	color: #fff;
}
.btn-twitter {
	color: #fff;
	background-color: #55acee;
}
.btn-twitter:hover {
	color: #fff;
	background-color: #4a9edf;
}
.btn-twitter:focus {
	color: #fff;
}
.btn-whatsapp {
	color: #fff;
	background-color: #25D366;
}
.btn-whatsapp:hover {
	color: #fff;
	background-color: #128C7E;
}
.btn-whatsapp:focus {
	color: #fff;
}
.btn-social {
    position: relative;
    padding-left: 44px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.btn-social.btn-lg {
    padding-left: 61px;
}
.btn-social>:first-child {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 32px;
    line-height: 34px;
    font-size: 1.6em;
    text-align: center;
    border-right: 1px solid rgba(0,0,0,0.2);
}
.btn-social.btn-lg>:first-child {
    line-height: inherit;
    width: 48px;
    /* font-size: 1.2em; */
}

.cursor-pointer {
    cursor: pointer;
}

.hero__tnc {
    position: absolute;
    bottom: 1em;
    right: 1em;
}