.promo-nav {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
    background-color: var(--dcs-gray-100);
    filter: drop-shadow(3px 6px 5px rgba(128, 128, 128, 0.35));
    border: solid var(--dcs-gray-300);
    border-width: thin;
}

.promo-nav-item {
    width: 20%;
    text-align: center;
    text-decoration: none;
    padding: 0.5rem;
    border-radius: 1rem;
    color: black;
    &.active{
        background: var(--dcs-primary);
        color: white;
        &:hover{
            background: var(--dcs-primary);
            color: white;
        }
    }
    &:hover{
        color: black;
        font-weight: bold;
    }
}

.dropbtn {
    background-color: white;
    color: var(--dcs-primary);
    padding: 0.3rem;
    font-size: 16px;
    border: none;
    width: 100%;
    cursor: pointer;
    box-shadow: 0px 0px 5px 0 rgba(0, 0, 0, 0.3);
    border-radius: 1rem;
}

/* The container <div> - needed to position the dropdown content */
.dropdown.promo {
    position: relative;
    display: none;
    width: 100%;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    width: 100%;
}

/* Links inside the dropdown */
.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: center;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
    background-color: #f1f1f1
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
    display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
    background-color: var(--dcs-primary);
    color: white;
}

.dropdown:focus .dropdown-content {
    outline: none;
    transform: translateY(20px);
    visibility: visible;
    opacity: 1;
}

.dropdown .db2 {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 10;
    display: none;
}

.dropdown:focus .db2 {
    display: inline-block;
}

.dropdown .db2:focus .dropdown-content {
    outline: none;
    visibility: hidden;
    opacity: 0;
}

.promocards{
    .leftcards, .rightcards{
        .card{
            pointer-events: all;
            border: solid var(--dcs-gray-300);
            border-width: thin;
            margin-bottom: 1.5rem;
            box-shadow: 3px 6px 5px rgba(128, 128, 128, 0.35);
            padding: 1.5rem;
            .card-header{
                padding: 0;
            }
            .card-footer, .card-body{
                padding: 1rem, 0;
            }
        }
    }
}

.text-promotion {
    color: #004C97;
    font-size: 1.25rem;
    font-weight: 500;
}


@media(max-width: 588px){
    .dropdown.promo{
        display: block;
    }
    .promo-nav{
        display: none;
    }
}