.storebadges{
    display: flex;
    flex-direction: row;
    align-items: center;
    a{
        height: 40px;
        &:hover{
            opacity: 85%;
        }
        img{
            height: 40px;
        }
    }
}

.instructionsdiv{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    position: relative;
    .picturediv{
        height: 100%;
        align-self: flex-start;
        position: absolute;
        .sticky{
            height: 100vh;
            position: sticky;
            top: 1.5rem;
            transition: opacity .5s ease-in-out;
            img{
                width: 100%;
                height: auto;
                max-width: 100%;
                
                margin-top: 50%;
                transform: translateY(-25%);
            }
        }
    }
    .textdiv{
        width: 50%;
        position: relative;
        right: -42vw;
        .steps{
            padding: 40vh 0 0 0;
        }
    }
}

#stepnumdiv{
    position: absolute;
    height: 100%;
    right: -3rem;
    // cursor: pointer;
    .stepnumbers{
        position: sticky;
        top: 45%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20rem;
        margin-bottom: 20rem;
        a{
            color: grey;
        }
    }
}
.mobileinstructionsdiv{
    display: none;
}

.stepnumberdiv {
    border-radius: 100%;
    background-color: var(--dcs-primary);
    display: flex;
    width: 2.5rem;
    position: relative;
    height: 2.5rem;
    align-items: center;
    justify-content: center;
    h4{
        margin: 0.5rem;
        color: white;
    }
}

.sgqrdiv {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .sgqrimgdiv{
        margin: auto;
        width: 15%;
        position: relative;
        overflow: hidden;
        &:hover{
            cursor: zoom-in;
            .SGQR{
                transform: scale(3) translate(-24px, -70px);
            }
        }
        .SGQR {
            width: 100%;
            position: relative;
            transition: transform .3s;
        }
    }
}

@media(max-width: 1500px){
    #stepnumdiv{
        right: 1rem;
    }
}

@media(max-width: 1279px){
    .mobileinstructionsdiv {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        // .mobileimgdiv{
        //     img{
        //         height: 100vh;
        //     }
        // }
        .mobilestepdiv{
            width: 100%;
            display: flex;
            align-items: center;
        }
    }
    .instructionsdiv{
        display: none;
    }
}

// @media(max-width: 991px){
//     .diners-sg-pay-app{
//         img{
//             border-radius: 30%;
//             filter: drop-shadow(10px 10px 3px grey);
//         }
//     }
// }
@media(max-width: 820px) and (orientation: portrait){
    .mobileinstructionsdiv{
        .mobileimgdiv{
            img{
                width: 100%;
                height: auto;
            }
        }
    }
}

@media(max-width: 1280px){
    .mobileinstructionsdiv{
        .mobilestepdiv{
            flex-direction: column;
            align-items: flex-start;
            .mobileimgdiv{
                margin-left: auto;
                margin-right: auto;
            }
            .numberandtext{
                display: flex;
                align-items: center;
                h5{
                    padding-left: 0.5rem;
                }
            }
        }
    }
    .sgqrdiv {
        flex-direction: column;
        align-items: flex-start;
        .sgqrimgdiv{
            margin: 0;
            width: 10rem;
            position: relative;
            .SGQR {
                width: 100%;
            }
        }
    }
}